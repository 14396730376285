import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import FontFaceObserver from 'fontfaceobserver';
import './index.css';
import * as serviceWorker from './serviceWorker';

new FontFaceObserver('Helvetica Neue Regular').load().then(() => {
  new FontFaceObserver('Helvetica Neue Light').load().then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  });
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
